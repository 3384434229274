.timetable {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.timetable th, .timetable td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}

.timetable th {
    background-color: #f0f0f0;
}

.time-input {
    width: 80px;
    text-align: center;
    border: none;
    background-color: transparent;
    outline: none;
}

.cell {
    position: relative;
    cursor: pointer;
}

.add-event-icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    cursor: pointer;
}

.add-event-icon svg {
    font-size: 1.2rem;
    color: #007bff;
}

.add-row {
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    background-color: #f0f0f0;
}
