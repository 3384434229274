.modal-body div[id^="react-select-"][id$="listbox"] {
  background-color: #fff !important;
}

.cursor-pointer {
  cursor: pointer;
}
.checkbox-medium {
  width: 18px;
  height: 18px;
  min-width: 18px;
}
/* Style for the CardElement wrapper */
.custom-card-element {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  gap: 15px; /* Add space between the fields */
}

/* Style for the Stripe elements */
.StripeElement {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Focused field border style */
.StripeElement--focus {
  border-color: #5469d4;
}

/* Invalid field border style */
.StripeElement--invalid {
  border-color: #9e2146;
}

.vertical-collpsed .navbar-brand-box {
  padding: 0 0.5rem;
}
